
import { defineComponent } from 'vue';
import Card from '@/views/new-design/components/Card.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import { Actions, Getters, Mutations } from '@/store/enums/MemberEnums';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import useBreakpoints from 'vue-next-breakpoints';

import { User } from '@/models/UserModel';
import toasts from '@/utils/toasts';
import { capitalize } from '@/utils/text';

export default defineComponent({
  name: 'member-table',
  components: {
    Card,
    DataTable,
  },
  data: () => ({
    memberTable: '',
    loading: false,
    checkedCustomers: [],
    table: {
      total: 0,
      loading: false,
      items: [],
      search: '',
    },
    debounceGetAgencies: Function(),
  }),
  computed: {
    ...mapGetters({
      allData: Getters.GET_MEMBERS,
      pagination: Getters.GET_PAGINATION,
      actionErrors: Getters.GET_ACTION_ERRORS,
    }),
    isLoading() {
      return this.loading ? this.loading : false;
    },
    dataText() {
      return this.table.total > 1 ? 'members' : 'member';
    },
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    tableHeaders() {
      return [
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'First Name',
          key: 'first_name',
          sortable: true,
        },
        {
          name: 'Last Name',
          key: 'last_name',
          sortable: true,
        },
        {
          name: 'Mobile',
          key: 'mobile_number',
          sortable: true,
          hidden: this.isMobile,
          align: 'center',
        },
        {
          name: 'Subscription',
          key: 'subscription',
          sortable: true,
          hidden: this.isMobile,
          align: 'center',
        },
        {
          name: 'Action',
          key: 'action',
          sortable: true,
          align: 'center',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchData: Actions.FETCH_MEMBERS,
      createData: Actions.CREATE_MEMBER,
      updateData: Actions.UPDATE_MEMBER,
      deleteData: Actions.DELETE_MEMBER,
    }),
    ...mapMutations({
      setMember: Mutations.SET_MEMBER,
    }),
    capitalFirst(str) {
      return capitalize(str);
    },
    formatList() {
      return this.allData.map((item) => {
        return {
          id: item.id,
          email: item.email,
          first_name: item.first_name,
          last_name: item.last_name,
          mobile_number: item.mobile_number,
          subscription: item.subscription ? item.subscription?.code : 'none',
        };
      });
    },
    async loadData() {
      if (this.loading) return;
      this.loading = true;

      return await this.fetchData()
        .then(async () => {
          this.table.items = await this.formatList();
          this.table.total = this.pagination.total;
          this.loading = false;
        })
        .catch((error) => {
          console.log('ERROR', error);
          this.loading = false;
        })
        .finally(() => (this.loading = false));
    },
    getStatus(data: User) {
      if (data?.verified_at === '') {
        return {
          type: 'warning',
          text: 'Verification Needed',
          value: true,
          textStatus: '',
        };
      }

      if (data?.verified_at !== '') {
        return { type: 'success', text: 'Active', value: true, textStatus: '' };
      }

      if (data?.deleted_at !== '') {
        return {
          type: 'danger',
          text: 'Deleted',
          value: false,
          textStatus: '',
        };
      }

      return { type: 'warning', text: 'Inactive', value: false };
    },

    onSearch(search) {
      this.table.search = search;
    },

    async viewDetail(row: User) {
      return this.$router.push({
        name: 'view-member',
        params: { member_id: row.id },
      });
    },

    async editDetail(row: User) {
      return toasts.alert(`Edit member under construction`);

      //this.setMember(row);
    },

    confirmDeletemember(row: User) {
      return toasts.confirm(
        `Are you sure you want to delete member? You can't undo this action...`,
        (response) => {
          console.log('isConfirmed', response);
          if (response.isConfirmed) {
            this.deleteMember(row);
          }
        }
      );
    },

    deleteMember(row: User) {
      this.deleteData(row.id)
        .then(() => {
          toasts.success('Member Successfully Deleted');

          this.loadData();
        })
        .catch(() => {
          this.loading = false;
          const { errors, message } = this.actionErrors;

          toasts.error(errors, message);
        });
    },

    handlememberCreated() {
      this.loadData();
    },

    handlememberUpdated() {
      this.loadData();
    },
  },

  async mounted() {
    await this.loadData();
  },
});
